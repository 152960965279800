import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSIONS } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Customer = lazy(() => import('../../container/master/customer'));
const CustomerGroup = lazy(() => import('../../container/master/customer-group'));
const CustomerGroupEdit = lazy(() => import('../../container/master/customer-group/Edit'));
const Employee = lazy(() => import('../../container/master/employee'));
const JobPackageIndex = lazy(() => import('../../container/master/job-package'));
const JobPackageCreate = lazy(() => import('../../container/master/job-package/Create'));
const JobPackageEdit = lazy(() => import('../../container/master/job-package/Edit'));
const Manufacturer = lazy(() => import('../../container/master/manufacturer'));
const IndexPackage = lazy(() => import('../../container/master/package'));
const CreatePackage = lazy(() => import('../../container/master/package/Create'));
const EditPackage = lazy(() => import('../../container/master/package/Edit'));
const RouteMaster = lazy(() => import('../../container/master/route'));
const Sparepart = lazy(() => import('../../container/master/sparepart'));
const Supplier = lazy(() => import('../../container/master/supplier'));
const Uom = lazy(() => import('../../container/master/uom'));
const Vehicle = lazy(() => import('../../container/master/vehicle'));
const VehicleModel = lazy(() => import('../../container/master/vehicle-model'));

function MasterRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/customer`} component={Customer} />
      <Route exact path={`${path}/customer-group`} component={CustomerGroup} />
      {permissions.includes(PERMISSIONS.master.sub.customerGroup.modify.value) && <Route path={`${path}/customer-group/:customerGroupId/edit`} component={CustomerGroupEdit} />}
      <Route exact path={`${path}/employee`} component={Employee} />
      <Route exact path={`${path}/job-package`} component={JobPackageIndex} />
      {permissions.includes(PERMISSIONS.master.sub.jobPackage.modify.value) && <Route exact path={`${path}/job-package/create`} component={JobPackageCreate} />}
      {permissions.includes(PERMISSIONS.master.sub.jobPackage.modify.value) && <Route exact path={`${path}/job-package/:jobPackageId/edit`} component={JobPackageEdit} />}
      <Route exact path={`${path}/manufacturer`} component={Manufacturer} />
      <Route exact path={`${path}/package`} component={IndexPackage} />
      {permissions.includes(PERMISSIONS.master.sub.package.modify.value) && <Route exact path={`${path}/package/create`} component={CreatePackage} />}
      {permissions.includes(PERMISSIONS.master.sub.package.modify.value) && <Route exact path={`${path}/package/:packageId/edit`} component={EditPackage} />}
      <Route exact path={`${path}/route`} component={RouteMaster} />
      <Route exact path={`${path}/sparepart`} component={Sparepart} />
      <Route exact path={`${path}/supplier`} component={Supplier} />
      <Route exact path={`${path}/uom`} component={Uom} />
      <Route exact path={`${path}/vehicle`} component={Vehicle} />
      <Route exact path={`${path}/vehicle-model`} component={VehicleModel} />
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/customer`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default MasterRoutes;
