const PERMISSIONS = {
  dashboard: {
    label: 'Dashboard',
    general: { label: 'General', value: 'dashboard general' },
    maintenance: { label: 'Workshop', value: 'dashboard maintenance' },
    warehouse: { label: 'Warehouse', value: 'dashboard warehouse' },
  },
  master: {
    views: [
      'master customer view',
      'master customer-group view',
      'master employee view',
      'master job-package view',
      'master manufacturer view',
      'master route view',
      'master service view',
      'master sparepart view',
      'master supplier view',
      'master uom view',
      'master vehicle view',
      'master vehicle-model view',
    ],
    label: 'Master',
    sub: {
      customer: {
        label: 'Customer',
        view: { label: 'View', value: 'master customer view' },
        modify: { label: 'Modify', value: 'master customer modify' },
      },
      customerGroup: {
        label: 'Customer Group',
        view: { label: 'View', value: 'master customer-group view' },
        modify: { label: 'Modify', value: 'master customer-group modify' },
      },
      employee: {
        label: 'Employee',
        view: { label: 'View', value: 'master employee view' },
        modify: { label: 'Modify', value: 'master employee modify' },
      },
      jobPackage: {
        label: 'Job Package',
        view: { label: 'View', value: 'master job-package view' },
        modify: { label: 'Modify', value: 'master job-package modify' },
      },
      manufacturer: {
        label: 'Manufacturer',
        view: { label: 'View', value: 'master manufacturer view' },
        modify: { label: 'Modify', value: 'master manufacturer modify' },
      },
      route: {
        label: 'Route',
        view: { label: 'View', value: 'master route view' },
        modify: { label: 'Modify', value: 'master route modify' },
      },
      package: {
        label: 'Service',
        view: { label: 'View', value: 'master package view' },
        modify: { label: 'Modify', value: 'master package modify' },
      },
      sparepart: {
        label: 'Sparepart',
        view: { label: 'View', value: 'master sparepart view' },
        modify: { label: 'Modify', value: 'master sparepart modify' },
      },
      supplier: {
        label: 'Supplier',
        view: { label: 'View', value: 'master supplier view' },
        modify: { label: 'Modify', value: 'master supplier modify' },
      },
      uom: {
        label: 'UoM',
        view: { label: 'View', value: 'master uom view' },
        modify: { label: 'Modify', value: 'master uom modify' },
      },
      vehicle: {
        label: 'Vehicle',
        view: { label: 'View', value: 'master vehicle view' },
        modify: { label: 'Modify', value: 'master vehicle modify' },
      },
      vehicleModel: {
        label: 'Vehicle Model',
        view: { label: 'View', value: 'master vehicle-model view' },
        modify: { label: 'Modify', value: 'master vehicle-model modify' },
      },
    },
  },
  vehicleLicense: {
    label: 'Vehicle License',
    view: { label: 'View', value: 'vehicle-license view' },
    modify: { label: 'Modify', value: 'vehicle-license modify' },
  },
  kimper: {
    label: 'Kimper',
    view: { label: 'View', value: 'kimper view' },
    modify: { label: 'Modify', value: 'kimper modify' },
  },
  maintenance: {
    label: 'Maintenance',
    views: ['maintenance vehicle-maintenance view', 'maintenance list-maintenance view', 'maintenance work-order view'],
    sub: {
      vehicleMaintenance: {
        label: 'Vehicle Maintenance',
        view: { label: 'View', value: 'maintenance vehicle-maintenance view' },
        modify: { label: 'Modify', value: 'maintenance vehicle-maintenance modify' },
      },
      listMaintenance: {
        label: 'List Maintenance',
        view: { label: 'View', value: 'maintenance list-maintenance view' },
        modify: { label: 'Modify', value: 'maintenance list-maintenance modify' },
      },
      workOrder: {
        label: 'Work Order',
        view: { label: 'View', value: 'maintenance work-order view' },
        modify: { label: 'Modify', value: 'maintenance work-order modify' },
      },
    },
  },
  operation: {
    label: 'Operation',
    views: ['operation daily view', 'operation trip view'],
    sub: {
      daily: {
        label: 'Daily Log',
        view: { label: 'View', value: 'operation daily view' },
        modify: { label: 'Modify', value: 'operation daily modify' },
      },
      trip: {
        label: 'Trip',
        view: { label: 'View', value: 'operation trip view' },
        modify: { label: 'Modify', value: 'operation trip modify' },
      },
    },
  },
  partRequisition: {
    label: 'Part Requisition',
    views: ['part-requisition part-requisition view', 'part-requisition purchase-order view', 'part-requisition sparepart-requirement view'],
    sub: {
      partRequisition: {
        label: 'Part Requisition',
        view: { label: 'View', value: 'part-requisition part-requisition view' },
        modify: { label: 'Modify', value: 'part-requisition part-requisition modify' },
        acknowledge: { label: 'Acknowledge', value: 'part-requisition part-requisition acknowledge' },
        approve: { label: 'Approve', value: 'part-requisition part-requisition approve' },
        reject: { label: 'Reject', value: 'part-requisition part-requisition reject' },
      },
      purchaseOrder: {
        label: 'Purchase Order',
        view: { label: 'View', value: 'part-requisition purchase-order view' },
        modify: { label: 'Modify', value: 'part-requisition purchase-order modify' },
        reject: { label: 'Reject', value: 'part-requisition purchase-order reject' },
      },
      sparepartRequirement: {
        label: 'Sparepart Requirement',
        view: { label: 'View', value: 'part-requisition sparepart-requirement view' },
      },
    },
  },
  warehouseInventory: {
    label: 'Warehouse Inventory',
    views: ['warehouse-inventory good-receipt view', 'warehouse-inventory good-issue view', 'warehouse-inventory stock-opname view', 'warehouse-inventory inventory view'],
    sub: {
      goodReceipt: {
        label: 'Good Receipt',
        view: { label: 'View', value: 'warehouse-inventory good-receipt view' },
        modify: { label: 'Modify', value: 'warehouse-inventory good-receipt modify' },
      },
      goodIssue: {
        label: 'Good Issue',
        view: { label: 'View', value: 'warehouse-inventory good-issue view' },
        modify: { label: 'Modify', value: 'warehouse-inventory good-issue modify' },
      },
      stockOpname: {
        label: 'Stock Opname',
        view: { label: 'View', value: 'warehouse-inventory stock-opname view' },
        modify: { label: 'Modify', value: 'warehouse-inventory stock-opname modify' },
      },
      inventory: {
        label: 'Inventory',
        view: { label: 'View', value: 'warehouse-inventory inventory view' },
        modify: { label: 'Modify', value: 'warehouse-inventory inventory modify' },
      },
    },
  },
  users: {
    label: 'Users',
    views: ['users user view', 'users role view'],
    sub: {
      user: {
        label: 'User',
        view: { label: 'View', value: 'users user view' },
        modify: { label: 'Modify', value: 'users user modify' },
      },
      role: {
        label: 'Role',
        view: { label: 'View', value: 'users role view' },
        modify: { label: 'Modify', value: 'users role modify' },
      },
    },
  },
};

export { PERMISSIONS };
