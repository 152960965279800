import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import Dashboard from './dashboard';
import Maintenance from './maintenance';
import Master from './master';
import Operation from './operation';
import PartRequisition from './part-requisition';
import User from './user';
import WarehouseInventory from './warehouse-inventory';
import withAdminLayout from '../../layout/withAdminLayout';
import { PERMISSIONS } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Kimper = lazy(() => import('../../container/kimper'));
const Profile = lazy(() => import('../../container/users/user/Profile'));
const VehicleLicense = lazy(() => import('../../container/vehicle-license'));

const Home = () => {
  const { path } = useRouteMatch();
  const permissions = useSelector((state) => state.auth.user.permissions);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}dashboard`} component={Dashboard} />
        {permissions.includes(PERMISSIONS.kimper.view) && <Route exact path={`${path}kimper`} component={Kimper} />}
        {permissions.some((permission) => PERMISSIONS.maintenance.views.includes(permission)) && <Route path={`${path}maintenance`} component={Maintenance} />}
        {permissions.some((permission) => PERMISSIONS.master.views.includes(permission)) && <Route path={`${path}master`} component={Master} />}
        {permissions.some((permission) => PERMISSIONS.operation.views.includes(permission)) && <Route path={`${path}operation`} component={Operation} />}
        {permissions.some((permission) => PERMISSIONS.partRequisition.views.includes(permission)) && <Route path={`${path}part-requisition`} component={PartRequisition} />}
        {permissions.some((permission) => PERMISSIONS.users.views.includes(permission)) && <Route path={`${path}users`} component={User} />}
        <Route path={`${path}profile`} component={Profile} />
        {permissions.includes(PERMISSIONS.vehicleLicense.view) && <Route exact path={`${path}vehicle-license`} component={VehicleLicense} />}
        {permissions.some((permission) => PERMISSIONS.warehouseInventory.views.includes(permission)) && (
          <Route path={`${path}warehouse-inventory`} component={WarehouseInventory} />
        )}
        <Route exact path={['/sign-in', '/']}>
          <Redirect to={`${path}dashboard`} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Home);
