import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSIONS } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const GoodReceiptNoteIndex = lazy(() => import('../../container/warehouse-inventory/good-receipt-note'));
const GoodReceiptNoteCreate = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/Create'));
const GoodReceiptNoteCreateReturn = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/CreateReturn'));
const GoodReceiptCreateUnusedReturn = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/CreateUnusedReturn'));
const GoodReceiptNoteShow = lazy(() => import('../../container/warehouse-inventory/good-receipt-note/Show'));

const GoodIssueNoteIndex = lazy(() => import('../../container/warehouse-inventory/good-issue-note'));
const GoodIssueNoteCreate = lazy(() => import('../../container/warehouse-inventory/good-issue-note/Create'));
const GoodIssueNoteShow = lazy(() => import('../../container/warehouse-inventory/good-issue-note/Show'));

const StockOpnameIndex = lazy(() => import('../../container/warehouse-inventory/stock-opname'));
const StockOpnameShow = lazy(() => import('../../container/warehouse-inventory/stock-opname/Show'));

const InventoryIndex = lazy(() => import('../../container/warehouse-inventory/inventory'));
const InventoryShow = lazy(() => import('../../container/warehouse-inventory/inventory/Show'));

function WarehouseInventoryRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/good-receipt-note`} component={GoodReceiptNoteIndex} />
      {permissions.includes(PERMISSIONS.warehouseInventory.sub.goodReceipt.modify.value) && (
        <Route exact path={`${path}/good-receipt-note/:purchaseOrderId/create`} component={GoodReceiptNoteCreate} />
      )}
      {permissions.includes(PERMISSIONS.warehouseInventory.sub.goodReceipt.modify.value) && (
        <Route exact path={`${path}/good-receipt-note/:returnSparepartId/return/create`} component={GoodReceiptNoteCreateReturn} />
      )}
      {permissions.includes(PERMISSIONS.warehouseInventory.sub.goodReceipt.modify.value) && (
        <Route exact path={`${path}/good-receipt-note/:goodIssueId/return/unused`} component={GoodReceiptCreateUnusedReturn} />
      )}

      <Route exact path={`${path}/good-receipt-note/:goodReceiptNoteId`} component={GoodReceiptNoteShow} />

      <Route exact path={`${path}/good-issue-note`} component={GoodIssueNoteIndex} />
      {permissions.includes(PERMISSIONS.warehouseInventory.sub.goodIssue.modify.value) && <Route exact path={`${path}/good-issue-note/create`} component={GoodIssueNoteCreate} />}
      <Route exact path={`${path}/good-issue-note/:goodIssueNoteId`} component={GoodIssueNoteShow} />

      <Route exact path={`${path}/stock-opname`} component={StockOpnameIndex} />
      <Route exact path={`${path}/stock-opname/:sparepartId`} component={StockOpnameShow} />

      <Route exact path={`${path}/inventory`} component={InventoryIndex} />
      <Route exact path={`${path}/inventory/:sparepartId`} component={InventoryShow} />
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/good-receipt-note`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default WarehouseInventoryRoutes;
