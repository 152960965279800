import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { PERMISSIONS } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const PartRequisitionIndex = lazy(() => import('../../container/part-requisition/part-requisition'));
const PartRequisitionCreate = lazy(() => import('../../container/part-requisition/part-requisition/Create'));
const PartRequisitionShow = lazy(() => import('../../container/part-requisition/part-requisition/Show'));

const PurchaseOrderIndex = lazy(() => import('../../container/part-requisition/purchase-order'));
const PurchaseOrderCreate = lazy(() => import('../../container/part-requisition/purchase-order/Create'));
const PurchaseOrderShow = lazy(() => import('../../container/part-requisition/purchase-order/Show'));

const SparepartRequirementIndex = lazy(() => import('../../container/part-requisition/sparepart-requirement'));

function PartRequisitionRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/part-requisition`} component={PartRequisitionIndex} />
      {permissions.includes(PERMISSIONS.partRequisition.sub.partRequisition.modify.value) && (
        <Route exact path={`${path}/part-requisition/create`} component={PartRequisitionCreate} />
      )}
      <Route exact path={`${path}/part-requisition/:partRequisitionId`} component={PartRequisitionShow} />

      <Route exact path={`${path}/purchase-order`} component={PurchaseOrderIndex} />
      <Route exact path={`${path}/purchase-order/create`} component={PurchaseOrderCreate} />
      <Route exact path={`${path}/purchase-order/:purchaseOrderId`} component={PurchaseOrderShow} />
      {permissions.includes(PERMISSIONS.partRequisition.sub.purchaseOrder.modify.value) && (
        <Route exact path={`${path}/purchase-order/:partRequisitionId/create`} component={PurchaseOrderCreate} />
      )}

      <Route exact path={`${path}/sparepart-requirement`} component={SparepartRequirementIndex} />

      <Route exact path={`${path}`}>
        <Redirect to={`${path}/part-requisition`} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default PartRequisitionRoutes;
