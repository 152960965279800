import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PERMISSIONS } from '../../config/permissions';

const NotFound = lazy(() => import('../../container/pages/404'));
const Index = lazy(() => import('../../container/users/user'));
const Create = lazy(() => import('../../container/users/user/Create'));
const Edit = lazy(() => import('../../container/users/user/Edit'));

const IndexRole = lazy(() => import('../../container/users/role'));
const CreateRole = lazy(() => import('../../container/users/role/Create'));
const EditRole = lazy(() => import('../../container/users/role/Edit'));

function UserRoutes() {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/user`} component={Index} />
      {permissions.includes(PERMISSIONS.users.sub.user.modify.value) && <Route exact path={`${path}/user/create`} component={Create} />}
      {permissions.includes(PERMISSIONS.users.sub.user.modify.value) && <Route exact path={`${path}/user/:userId/edit`} component={Edit} />}
      <Route exact path={`${path}/role`} component={IndexRole} />
      {permissions.includes(PERMISSIONS.users.sub.role.modify.value) && <Route exact path={`${path}/role/create`} component={CreateRole} />}
      {permissions.includes(PERMISSIONS.users.sub.role.modify.value) && <Route exact path={`${path}/role/:roleId/edit`} component={EditRole} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default UserRoutes;
